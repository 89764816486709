// Here you can add other styles

h1 {
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 0;
}

.c-app {
  background-color: rgb(246, 246, 247);
  color: rgb(32, 34, 35);
}

.form-control, .form-control:focus {
  color: rgb(32, 34, 35);
}

.c-main {
  padding-top: 1.5rem;
}

/* Text */
.text-warning {
  color: #8a6116!important;
}

.c-sidebar {
  background-color: rgb(246, 246, 247);
  border-right: 1px solid #d8dbe0;
}

.c-sidebar .c-sidebar-brand {
  background: #fff;
  color: #000;
  justify-content: left;
  padding: 0 1.5rem;
}

.c-sidebar .c-sidebar-brand:hover {
  text-decoration: none;
}

.c-sidebar-nav.ps{
  padding-top: 1rem;
}

.c-sidebar-nav-item, .c-sidebar-nav-dropdown{
  padding: 0 0.5rem;
  font-weight: 600;
  border-radius: 0.3rem;
}

.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: #000;
}

.c-sidebar-nav-link, .c-sidebar-nav-dropdown-toggle {
  padding: 0.5rem 1rem;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background: rgb(241, 242, 243);
  color: #000;
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #000;
  background: rgb(237, 238, 239);
}

.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: none;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: #000;
}

html:not([dir=rtl]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link, html:not([dir=rtl]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle{
  padding-left: 25px;
}

.c-sidebar-nav-dropdown-items{
  transition: none;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle::after{
  display: none;
}

.c-sidebar .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-items .c-sidebar-nav-link{
  font-weight: 500;
  color: rgba(109, 113, 117, 1);
}

.c-sidebar-nav-dropdown-items .c-sidebar-nav-item{
  padding-right: 0;
}

.c-header-nav-link__user-name{
  margin-left: 10px;
  font-weight: 500;
}

.c-avatar {
  background: #39f;
  color: #fff;
  font-weight: bold;
  width: 30px;
  height: 30px;
}

.c-header .dropdown-menu, .navbar .dropdown-menu, [data-display^=static] ~ .dropdown-menu {
  margin: 0;
}

a:hover {
  color: rgba(31, 81, 153, 1);
}

/* Tables */
.table thead th {
  border: none;
  font-weight: 500;
}

.table-hover tbody tr:hover {
  background: #f9fafb;
}

.table_va-m td{
  vertical-align: middle;
}

/* Cards */
.card-header {
  border-bottom: none;
  font-weight: 500;
  font-size: 1rem;
  padding-bottom: 0;
}

.card-highlight{

}

.card-highlight__value,
.card-highlight__value .dropdown-toggle {
  font-size: 1.1rem;
  font-weight: 500;
  border: none;
}

.card-highlight__value .dropdown-toggle:hover {
  opacity: 0.9;
}

.card-highlight__value .dropdown-toggle {
  padding: 0;
}

/* Badge */
.badge {
  padding: 4px 7px;
  font-size: 13px;
  font-weight: 400;
}

.badge-secondary{
  background-color: #dfe3e8;
  color: #454f5b;
}
.badge-warning {
  background-color: #ffea8a;
}
.badge-success {
  background-color: rgb(174 233 209);
  color: #454f5b;
}

/* Buttons */
.btn-primary {
  background: linear-gradient(180deg,#6371c7,#5563c1)
}
.btn-warning {
  background: linear-gradient(180deg,#e55353, #e34d4d)
}
.btn-light {
  background: rgba(0, 0, 0, 0) linear-gradient(180deg, #fff, #f9fafb);
  border: 1px solid rgb(196, 205, 213);
  color: #212b36;
}
.btn-light:hover {
  background: linear-gradient(180deg,#f9fafb,#f4f6f8);
  border-color: #c4cdd5;
  color: #212b36;
}
.btn-light.disabled,
.btn-light:disabled,
.btn-light.disabled:hover,
.btn-light:disabled:hover{
  cursor: default;
}


.btn:focus, .btn.focus {
  box-shadow: none;
}
.dropdown-item.active, .dropdown-item:active{
  text-decoration: none;
  color: #455164;
  background-color: #ebedef;
  opacity: 0.9;
}

/* Orders */
.order-items .table{
  margin-bottom: 0;
}

.order-items .table tr:first-child td {
  border-top: none;
}

.order-items .table tr td:first-child {
  padding-right: 0;
  padding-left: 0;
}

.order-items .table tr td:last-child {
  padding-right: 0;
}

.font-weight-500 {
  font-weight: 500;
}

.order-totals .row {
  padding-top: 10px;
}

/* Forms */
.prepend-inside__text{
  margin-right: -20px;
}
.input-group_prepend-inside {
  position: relative;
}
.input-group_prepend-inside__text {
  position: absolute;
  top: 7px;
  left: 15px;
  color: #768192;
}
.input-group_prepend-inside .form-control {
  padding-left: 50px;
}
.custom-control-input:checked ~ .custom-control-label::before{
  border-color: #5563c1;
  background-color: #5563c1;
}
input.input-no-arrows::-webkit-outer-spin-button,
input.input-no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-control:disabled, .form-control[readonly]{
  background-color: #eeeeee;
}

/* Toast */
.Toastify__toast-container--top-right {
  top: 60px;
}

.Toastify__toast {
  color: #686e73;
  border-radius: 2px;
}

.Toastify__close-button{
  color: #686e73;
}

.Toastify__toast--success {
  background: #f0faed;
  border-left: 5px solid #acd4a0;
}

.Toastify__toast--error {
  background: #faeded;
  border-left: 5px solid #d4a0a0;
}

/* Drag and drop */
body.grabbing, .draggable-handler {
  cursor: grab;
}

/* Product Form */
.table-product-variants{
  background-color: #f3f3f3;//rgb(237, 238, 239);;
}
.table-product-variants tr{
  background: #fff;
}
.table-product-variants td,
.table-product-variants-item_is-sorting td{
  padding: 12px 6px;
}
.table-product-variants td:first-child,
.table-product-variants-item_is-sorting td:first-child{
  padding-left: 12px;
}
.table-product-variants td:last-child,
.table-product-variants-item_is-sorting td:last-child{
  padding-right: 12px;
}
/*.table-product-variants .btn,
.table-product-variants .btn svg,
.table-product-variants-item_is-sorting .btn,
.table-product-variants-item_is-sorting .btn svg{
  margin: 0;
  padding: 0;
}*/
.table-product-variants .upload-area,
.table-product-variants-item_is-sorting .upload-area{
  height: 120px;
  width: 120px;
}
.table-product-variants-item_is-sorting {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 5px #acacac;
  border-radius: 3px;
}

// Uploader
.upload-area {
  border: 2px dashed #ccc;
  height: 170px;
  flex-direction: column;
  text-align: center;
  border-radius: 3px;
  display: flex;
  justify-content: center;
}

// Dropdowns
.dropdown-menu {
  box-shadow: 0 0 3px #e8e8e8;
}

// Loaders
.loading-overlay {
  content: "";
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}

/* List group */
.list-group-item-secondary {
  color: #3c4b64;
  background: #fff;
}
.list-group-item-secondary.list-group-item-action:hover {
  color: #3c4b64;
}
.list-group-item-secondary.list-group-item-action.active {
  background: #fff;
  color: #39f;
  border-color: rgba(0, 0, 21, 0.125);
}
.list-group-item-secondary.list-group-item-action.active:before {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 80%;
  left: 0;
  top: 10%;
  background: #39f;
}
.list-group-item-secondary.list-group-item-action:hover {
  background: #f9fafb;
}

/* Calendar */
.react-calendar {
  border-color: #d8dbe0;
  border-radius: 3px;
  padding: 5px;
}

.react-calendar__tile {
  border-radius: 3px;
}

/* Navs */
.nav-pills .nav-link {
  border-radius: 0;
}
.nav-pills .nav-link.active {
  background: none;
  border-bottom: 3px solid rgba(51, 153, 255, 0.38);
}

.nav-pills .nav-link:hover{
  border-bottom: 3px solid #ececec;
}

/* Categories */
.table-categories .actions .btn{
  opacity: 0;
}
.table-categories tr:hover .actions .btn{
  opacity: 1;
}

/* DateRange picker */
//.rdrDefinedRangesWrapper {
//  display: none;
//}

/* Print */
.print {
  display: none;
}
@media print {
  .no-print, .c-header, .Toastify, .c-sidebar{
    display:none;
  }
  .c-wrapper{
    margin-left: 0 !important;
  }
  .print {
    display: block;
  }
  .c-app {
    background-color: #fff;
  }
}

/* Filter sidebar */
.c-sidebar.c-sidebar-filters {
  right: 0;
  color: #000;
  width: 330px;
  flex-basis: 330px;
  background: #fff;
}

html:not([dir=rtl]) .c-sidebar.c-sidebar-filters{
  margin-right: -330px;
}

.c-sidebar .react-datepicker,
.c-sidebar .react-datepicker__month-container{
  width: 100%;
}

/* Datepicker */
.react-datepicker{
  border: none;
}
.react-datepicker__day.react-datepicker__day--outside-month {
  visibility: hidden;
}
.react-datepicker__header{
  background: none;
  border-bottom: none;
}
.react-datepicker__month {
  margin: 0;
  border: none;
}
.react-datepicker__current-month{
  font-weight: 400;
  font-size: 13px;
  margin-top: -3px;
  margin-bottom: 10px;
  color: #000;
}
.react-datepicker__navigation{
  background: #EFF2F7;
  border-radius: 5px;
  width: 28px;
  height: 28px;
}
.react-datepicker__navigation:hover{
  background: #E1E7F0;
}
.react-datepicker__navigation:focus{
  outline: none;
}
.react-datepicker__navigation:active{
  opacity: 0.8;
}
.react-datepicker__navigation-icon::before{
  border-width: 2px 2px 0 0;
  border-color: #000!important;
  width: 7px;
  height: 7px;
  top: 12px;
}
.react-datepicker__navigation-icon--previous::before{
  right: -3px;
}
.react-datepicker__navigation-icon--next::before{
  left: -3px;
}
.react-datepicker__day-names, .react-datepicker__week {
  display: flex;
}
.react-datepicker__week{
  margin-bottom: 0.5em;
}
.react-datepicker__day-name, .react-datepicker__day {
  flex: 1 0;
}
.react-datepicker__day-name{
  color: #768192;
}
.react-datepicker__day{
  margin: 0;
}
.react-datepicker__day:hover{
  border-radius: 5px;
}
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: #39f;
}
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover {
  border-radius: 0;
  background-color: #39f!important;
}
.react-datepicker__day--range-start,
.react-datepicker__day--range-start:hover,
.react-datepicker__day--selecting-range-start{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #39f;
}
.react-datepicker__day--range-end,
.react-datepicker__day--range-end:hover{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #39f;
}
.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range){
  background: #fff;
  color: #000;
}
.react-datepicker__day--keyboard-selected:hover{
  background-color: #f0f0f0;
}